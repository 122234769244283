<template>
  <div class="container">
    <div class="login">
      <form name="form" @submit.prevent="handleLogin">
        <br />
        <br />
        <br />
        <div class="form-title">
          <span class="form-title">Bem-vindo(a).</span>
          <span class="form-subtitle">Por favor, autentique-se.</span>
        </div>
        <div style="padding-left:35%" v-if="manutencao">
          <template>
            <Message severity="warn" style="width:46%;">O Sistema encontra-se em Manutenção!</Message>
          </template>
        </div>
        <div style="padding-left:35%" v-if="!manutencao">
          <template v-if="this.expired">
            <Message severity="warn" style="width:46%;">Sua sessão expirou efetue um novo Login!</Message>
          </template>
          <!--<template v-if="validation.hasError('login.nomeAcesso')">
            <Message severity="error" style="width:46%;">{{ validation.firstError('login.nomeAcesso') }}</Message>
          </template>
          <template v-else-if="validation.hasError('login.senhaAcesso')">
            <Message severity="error" style="width:46%">{{ validation.firstError('login.senhaAcesso') }}</Message>
          </template>-->
          <template v-if="this.erro">
            <Message severity="error" style="width:46%">{{ this.erro }}</Message>
          </template>
        </div>
        <div v-if="!manutencao">
          <div class="d-flex justify-content-center">
            <div class="col-md-3 mb-3">
              <span class="p-fluid">
                <InputText @input="removerErro()" id="usuario" type="text" placeholder="Documento" autocomplete="off"
                  tabindex="1" v-model="login.nomeAcesso" />
              </span>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="col-md-3 mb-1">
              <span class="p-fluid">
                <InputText @input="removerErro()" id="senha" type="password" placeholder="Senha" autocomplete="off"
                  tabindex="2" v-model="login.senhaAcesso" />
              </span>
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex justify-content-center" v-if="!manutencao">
          <span class="p-fluid">
            <Button label="Entrar" type="submit" class="p-button-primary p-button-danger btnEntrar" style="width:150px" />
          </span>
        </div>
        <div v-if="!manutencao">
          <br />
          <a @click="credenciar()">Não possui senha? Clique aqui para se credenciar.</a>
        </div>
        <div v-if="!manutencao">
          <br />
          <a @click="esqueceuSenha()">Esqueceu Sua Senha?</a>
        </div>
        <br />
        <div class="copyright">© {{ new Date().getFullYear() }} - GPI - Gestão Pública Integrada</div>
      </form>
    </div>
    <Dialog header="Credenciamento" v-model:visible="showCredenciar" :modal="true" :closable="false"
      :contentStyle="{ overflow: 'visible' }" :style="{ width: '70vw' }">
      <Message :closable="false" severity="error" v-if="erros && erros.length > 0">
        <ul class="mt-3">
          <li :key="erro" v-for="erro in erros">{{ erro }}</li>
        </ul>
      </Message>
      <template v-if="etapa == 1">
        <div id="topo" class="container scrolable-dialog">
          <div class="row">
            <div class="col-md-4 mb-3">
              <label for="doc">CPF:</label>
              <span class="p-fluid">
                <InputMask id="doc" @blur="validarDocumento()" v-model="cred.documento" key="F" mask="999.999.999-99" />
              </span>
            </div>
          </div>
        </div>

        <hr />
        <div class="container">
          <div class="row">
            <div>
              <Button @click="validarCredenciar()" label="Avançar" class="p-button-primary mr-2" icon="pi pi-check" />
              <Button @click="hideCredenciar()" type="button" label="Fechar" class="p-button-primary mr-2 p-button-danger"
                icon="pi pi-times" />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="etapa == 2">
        <div class="container text-left">
          <div class="row d-flex align-items-end">
            <div class="col-md-3 mb-2">
              <label for="tipoTomador">CPF *</label>
              <span class="p-fluid">
                <InputText v-model="cred.documento" type="text" disabled />
              </span>
            </div>

            <div class="col-md-6 mb-2">
              <label for="tipoTomador">Nome: *</label>
              <span class="p-fluid">
                <InputText v-model="cred.nome" type="text" :disabled="bloquearEdicaoPessoa" />
              </span>
            </div>

          </div>

          <hr />

          <div class="row d-flex align-items-end">
            <div class="col-md-3 mb-2">
              <label for="tipoTomador">CEP: *</label>
              <span class="p-fluid">
                <div class="p-inputgroup">
                  <InputMask @blur="searchLocalidadePorCep()" v-model="cred.cep" mask="99.999-999" placeholder="Pesquisar"
                    :disabled="bloquearEdicaoEndereco" />
                  <Button @click="searchLocalidadePorCep()" type="button" icon="pi pi-search" class="p-button"
                    title="Pesquisar CEP" :disabled="bloquearEdicaoEndereco" />
                </div>
              </span>
            </div>

            <div class="col-md-3 mb-2">
              <label for="munic">Município: *</label>
              <span class="p-fluid">
                <AutoComplete id="munic" v-model="selectedMunicipio" :dropdown="true" :suggestions="filteredLocalidades"
                  @item-select="setLocalidades($event)" @complete="searchLocalidade($event)" field="detail"
                  placeholder="Pesquisar" :disabled="bloquearEdicaoEndereco" />
              </span>
            </div>

            <div class="col-md-3 mb-2">
              <label for="tipoTomador">Estado:</label>
              <span class="p-fluid">
                <InputText v-model="nomeEstado" type="text" disabled />
              </span>
            </div>

            <div class="col-md-3 mb-2">
              <label for="tipoTomador">País:</label>
              <span class="p-fluid">
                <InputText v-model="nomePais" type="text" disabled />
              </span>
            </div>

            <div class="col-md-3 mb-2">
              <label for="tipoTomador">Bairro: *</label>
              <span class="p-fluid">

                <AutoComplete id="bairr" v-model="selectedBairro" :dropdown="true" :suggestions="filteredBairros"
                  @item-select="setBairro($event)" @complete="searchBairro($event)" field="nomeBairro"
                  placeholder="Pesquisar" :disabled="bloquearEdicaoEndereco" />

              </span>
            </div>

            <div class="col-md-5 mb-2">
              <label for="tipoTomador">Logradouro: *</label>
              <span class="p-fluid">
                <AutoComplete id="logra" v-model="selectedLogradouro" :dropdown="true" :suggestions="filteredLogras"
                  @item-select="setLogradouro($event)" @complete="searchLogradouro($event)" placeholder="Pesquisar"
                  field="detail" :disabled="bloquearEdicaoEndereco">
                  <template #item="slotProps">
                    <div class="p-fluid" style="max-width: 50vw">
                      {{ slotProps.item.detail }}
                    </div>
                  </template>
                </AutoComplete>
              </span>
            </div>


            <div class="col-md-2 mb-2">
              <label for="tipoTomador">Número: *</label>
              <span class="p-fluid">
                <InputText v-model="cred.numero" type="text" :disabled="bloquearEdicaoEndereco" />
              </span>
            </div>

            <div class="col-md-6 mb-2">
              <label for="tipoTomador">Complemento:</label>
              <span class="p-fluid">
                <InputText v-model="cred.complemento" type="text" maxlength="40" :disabled="bloquearEdicaoEndereco" />
              </span>
            </div>
          </div>

          <hr />

          <div class="row d-flex align-items-end">
            <div class="col-md-6 mb-2">
              <label for="tipoTomador">E-mail: *</label>
              <span class="p-fluid">
                <InputText v-model="cred.email" type="text" />
              </span>
            </div>

            <div class="col-md-3 mb-2">
              <label for="tipoTomador">Telefone:</label>
              <span class="p-fluid">
                <InputMask v-model="cred.contato" mask="(99) 9999-9999" />
              </span>
            </div>

            <div class="col-md-3 mb-2">
              <label for="tipoTomador">Celular:</label>
              <span class="p-fluid">
                <InputMask v-model="cred.contatoCelular" mask="(99) 99999-999?9" />
              </span>
            </div>
          </div>

          <template v-if="!cred.idUsuario">
            <hr />
            <div class="row d-flex align-items-end">
              <div class="col-md-3 mb-2">
                <label for="tipoTomador">Senha: *</label>
                <span class="p-fluid">
                  <InputText v-model="cred.senha" type="password" autocomplete="off" />
                </span>
              </div>

              <div class="col-md-3 mb-2">
                <label for="tipoTomador">Confirme a Senha: *</label>
                <span class="p-fluid">
                  <InputText v-model="cred.senhaConfirma" type="password" autocomplete="off" />
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <hr />
            <label style="font-weight: bold;">Ja existe um Login registrado para o documento em um dos nossos serviços da
              prefeitura, confirme o credenciamento abaixo e utilize sua senha existente de acesso, caso necessário após
              finalizar o credenciamento, utilize a opção Esqueceu sua senha. </label><br />
            <hr />
            <div class="row d-flex align-items-end">
              <div class="col-md-3 mb-2">
                <label for="login">Login: </label>
                <span class="p-fluid">
                  <InputText v-model="cred.documento" type="text" autocomplete="off" :disabled="true" />
                </span>
              </div>
            </div>
          </template>

        </div>
        <hr />
        <div class="container">
          <div class="row">
            <div>
              <Button @click="finalizarCredenciar()" label="Finalizar" class="p-button-primary mr-2" icon="pi pi-check"
                :disabled="credenciadoComSucesso" />
              <Button @click="hideCredenciar()" type="button" label="Fechar" class="p-button-primary mr-2 p-button-danger"
                icon="pi pi-times" />
            </div>
          </div>
        </div>
      </template>
    </Dialog>
    <Dialog header="Esqueceu sua Senha?" v-model:visible="showEsqueceu" :modal="true" :closable="false"
      :style="{ width: '30vw' }">
      <div class="container scrolable-dialog">
        <div class="row">
          <div class="d-flex justify-content-center">
            <label>Informe o tipo e seu respectivo Documento para recuperação de senha:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-15 mb-2">
            <label for="tipoPessoa">Tipo: </label>
            <span class="p-fluid">
              <Dropdown id="tipoPessoa" v-model="selectedTipoPessoa" :options="tiposPessoa" optionLabel="name"
                placeholder="Selecione um Tipo de Pessoa" />
            </span>
          </div>
        </div>
        <div class="row">
          <br />
        </div>
        <div class="row">
          <div class="d-flex justify-content-center">
            <div class="col-md-15 mb-2">
              <span class="p-fluid">
                <template v-if="selectedTipoPessoa && selectedTipoPessoa.key === 'F'">
                  <InputMask id="recuperaLogin" type="text" placeholder="CPF" autocomplete="off" v-model="loginNovaSenha"
                    mask="999.999.999-99" key="F" />
                </template>
                <template v-else>
                  <InputMask id="recuperaLogin" type="text" placeholder="CNPJ" autocomplete="off" v-model="loginNovaSenha"
                    mask="99.999.999/9999-99" key="J" />
                </template>
              </span>
            </div>
          </div>
        </div>

      </div>
      <hr />
      <div class="container">
        <div class="row">
          <div>
            <Button @click="requisitarNovaSenha()" label="Recuperar Senha" class="p-button-primary mr-2"
              icon="pi pi-check" :disabled="loading" />
            <Button @click="hideNovaSenha()" type="button" label="Fechar" class="p-button-primary mr-2 p-button-danger"
              icon="pi pi-times" />
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog header="Seleção Endereço" v-model:visible="showSelectLogra" :modal="true" :closable="false">
      <div class="container scrolable-dialog selecao-contribuinte">
        <div class="row">
          <DataTable :value="logradouroList" :paginator="true" :rows="5" style="min-width: 100%">
            <Column field="detail" header="Endereço:"></Column>
            <Column headerStyle="width: 58px" bodyStyle="text-align: center">
              <template #body="objeto">
                <Button type="button" icon="pi pi-check" title="Selecionar"
                  @click="preencherDadosLogradouro(objeto.data)"></Button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <hr />

      <div class="container">
        <div class="row">
          <div>
            <Button @click="showSelectLogra = false" type="button" label="Fechar"
              class="p-button-primary mr-2 p-button-danger" icon="pi pi-times" />
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Login from "../models/acesso/Login";
import Credenciar from "../models/acesso/Credenciar";
import LoginService from "../services/login.service";
//import SimpleVueValidation from "simple-vue-validator";
//const Validator = SimpleVueValidation.Validator;
import Util from "../utils/util";

export default {
  name: "login",

  data() {
    return {
      erro: null,
      etapa: 1,
      erros: [],
      loading: false,
      currentUser: null,
      expired: null,
      showCredenciar: false,
      login: new Login(),
      loginNovaSenha: null,
      manutencao: false,
      selectedTipo: null,
      tiposUsuario: [
        { name: "Pessoa Jurídica", key: "J" },
        { name: "Pessoa Física", key: "F" },
      ],
      emailCredenciamento: null,
      cred: new Credenciar(),
      showEsqueceu: false,
      selectedTipoPessoa: null,
      tiposPessoa: [
        { name: "Pessoa Física", key: "F" }
      ],
      selectedMunicipio: null,
      filteredLocalidades: null,
      filteredBairros: null,
      selectedBairro: null,
      selectedLogradouro: null,
      filteredLogras: null,
      selectedCepLogra: null,
      logradouroList: [],
      showSelectLogra: false,
      bloquearEdicaoEndereco: false,
      bloquearEdicaoPessoa: false,
      credenciadoComSucesso: false
    };
  },
  created() {
    this.limparDadosPessoa();
    this.selectedTipoPessoa = this.tiposPessoa[0];
    this.selectedTipo = this.tiposUsuario[0];
    this.expired = this.$route.query.expired;
    if (this.$route.query.manutencao && this.$route.query.manutencao == "N") {
      this.manutencao = false;
    }
  },
  /*validators: {
    "login.nomeAcesso": function (value) {
      return Validator.value(value)
        .required("Campo Login Obrigatório")
        .maxLength(200, "Login deve ser menor ou igual a 200");
    },
    "login.senhaAcesso": function (value) {
      return Validator.value(value)
        .required("Campo Senha Obrigatório")
        .maxLength(50, "Senha deve ser menor ou igual a 50");
    },
  },*/
  mounted() {
    this.processando(false);
  },
  computed: {
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    urlSistema() {
      return this.$store.getters.getUrlSistema;
    },
    nomeEstado() {
      return this.selectedMunicipio && this.selectedMunicipio.estado
        ? this.selectedMunicipio.estado.detail
        : "";
    },

    nomePais() {
      return this.selectedMunicipio &&
        this.selectedMunicipio.estado &&
        this.selectedMunicipio.estado.pais
        ? this.selectedMunicipio.estado.pais.detail
        : "";
    }
  },
  methods: {
    validarDocumento() {
      var validar = false;
      validar = Util.validarCPFCNPJ(this.documento);

      if (!validar && this.documento) {
        console.log(validar);
        this.$toast.add({
          severity: "warn",
          summary: "CPF/CNPJ inválido!",
        });
      }

      return validar;
    },
    removerErro() {
      this.erro = null;
    },
    handleLogin() {
      //this.$validate().then((success) => {
      //  if (success) {
      this.processando(true);
      this.loginUser();
      this.processando(false);
      //}
      //});
    },
    async loginUser() {
      await LoginService.login(this.idCliente, this.login).then(
        (data) => {
          console.log(data);
          //Verificar tipo de usuário...
          if (data.acessoMaster && data.acessoMaster === 'S') {
            this.$router.push('usuarioCredito');
          } else {
            this.$router.push('homeCredito');
          }
        },
        (error) => {
          this.erro = "Login ou Senha inválidos!";
          if (typeof error.response.data === "string") {
            this.erro = error.response.data;
          }
        }
      )
      await this.$root.fetchFuncionalidades();
      await this.$root.atualizarDadosHeader();
    },
    processando(show) {
      if (show) {
        this.loading = true;
        this.$root.$emit("loading:show");
      } else {
        this.loading = false;
        this.$root.$emit("loading:hide");
      }
    },

    validarCredenciar() {
      this.processando(true);
      this.bloquearEdicaoPessoa = false;
      this.bloquearEdicaoEndereco = false;
      this.cred.idCliente = this.idCliente;
      LoginService.validarCredenciar(this.idCliente, this.cred).then(
        (response) => {
          this.cred = response.data;
          if (this.cred.idPessoa && this.cred.bairro) {
            this.selectedBairro = this.cred.bairro;
          }
          if (this.cred.idPessoa && this.cred.cepLogradouro) {
            this.selectedCepLogra = this.cred.cepLogradouro;
          }
          if (this.cred.idPessoa && this.cred.localidade) {
            this.selectedMunicipio = this.cred.localidade;
          }
          if (this.cred.pessoa && this.cred.pessoa.nomeGeral && this.cred.pessoa.nomeGeral != '') {
            this.bloquearEdicaoPessoa = true;
          }
          if (this.selectedMunicipio && this.selectedMunicipio.id) {
            this.bloquearEdicaoEndereco = true;
          }
          this.etapa = 2;
        },
        (error) => {
          this.erros = [];
          let erro = "Não foi possível validar o documento!";
          if (error.response.data) {
            erro = error.response.data;
            this.erros.push(...erro);
          } else {
            this.erros.push(erro);
          }

        }
      ).then(() => {
        this.processando(false);
      });
    },

    credenciar() {
      this.limparDadosPessoa();
      this.etapa = 1;
      this.showCredenciar = true;
    },
    hideCredenciar() {
      this.loading = false;
      this.showCredenciar = false;
    },
    requisitarCredenciar() {
      this.loading = true;
      this.erros = [];
      this.cred.tipo = this.selectedTipo.key;
      LoginService.credenciar(this.idCliente, this.cred).then(
        (response) => {
          console.log(response);
          this.cred = response.data;
          this.emailCredenciamento = this.cred.email;
          this.showCredenciar = false;
        },
        (error) => {
          const erros = error.response.data;
          this.erros.push(...erros);
          this.loading = false;
        }
      );
    },
    finalizarCredenciar() {
      this.loading = true;
      this.erros = [];
      if (this.cred.email == null || this.cred.email == '') {
        this.$toast.add({
          severity: "warn",
          summary: "Informe um e-mail valido!",
        });
      } else {
        this.cred.bairro = (this.selectedBairro && this.selectedBairro.id ? this.selectedBairro : null);
        this.cred.localidade = (this.selectedMunicipio && this.selectedMunicipio.id ? this.selectedMunicipio : null);
        this.cred.logradouro = (this.selectedLogradouro && this.selectedLogradouro.id ? this.selectedLogradouro : null);
        LoginService.finalizarCredenciar(this.cred).then(
          (response) => {
            console.log(response);
            this.credenciadoComSucesso = true;
            this.$toast.add({
              severity: "success",
              summary: "Credenciamento efetuado com sucesso!",
            });
            this.loading = false;
          },
          (error) => {
            const erros = error.response.data;
            this.erros.push(...erros);
            this.loading = false;
          }
        );
      }
    },
    hideFinalizarCredenciar() {
      this.loading = false;
    },
    isEmailValid: function (email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    esqueceuSenha() {
      this.limparDadosPessoa();
      this.showEsqueceu = true;
    },
    hideNovaSenha() {
      this.loading = false;
      this.showEsqueceu = false;
    },
    limparDadosPessoa() {
      this.credenciadoComSucesso = false;
      this.cred = new Credenciar();
      this.selectedMunicipio = null;
      this.filteredLocalidades = null;
      this.filteredBairros = null;
      this.selectedBairro = null;
      this.selectedLogradouro = null;
      this.filteredLogras = null;
      this.loginNovaSenha = null;
      this.logradouroList = [];
      this.showSelectLogra = false;
      this.selectedCepLogra = null;
    },
    setLocalidades(event) {
      this.processando(true);
      this.selectedMunicipio = event.value;
    },

    selectMunicipioSede(municipio) {
      this.selectedMunicipio = municipio;
    },

    searchLocalidade(event) {
      setTimeout(async () => {
        this.filteredLocalidades =
          await LoginService.findAllLocalidadeByFiltro(event.query);
      }, 250);
    },

    setBairro(event) {
      this.processando(true);
      this.selectedBairro = event.value;
      this.processando(false);
    },

    searchBairro(event) {
      setTimeout(async () => {
        this.filteredBairros =
          await LoginService.findAllBairroByFiltro(
            this.idCliente,
            event.query
          );
      }, 250);
    },

    setLogradouro(event) {
      this.processando(true);
      this.selectedLogradouro = event.value;
      this.processando(false);
    },

    searchLogradouro(event) {
      setTimeout(async () => {
        this.filteredLogras =
          await LoginService.findAllLogradouroByFiltro(
            this.idCliente,
            event.query
          );
      }, 250);
    },

    searchLocalidadePorCep() {
      if (this.cred.cep !== "") {
        this.processando(true);
        let cep = this.cred.cep;
        cep = cep.replace(".", "");
        cep = cep.replace("-", "");
        LoginService.findAllLograByCep(cep)
          .then(
            (response) => {
              if (response.data.length && response.data.length > 1) {
                this.logradouroList = response.data;
                this.showSelectLogra = true;
              } else if (response.data.length > 0) {
                this.selectedCepLogra = response.data[0];
                this.preencherDadosLogradouro(this.selectedCepLogra);
              }
            },
            (error) => {
              let errorMessage = "Houve um problema ao listar Logradouros!";
              if (error.response) {
                errorMessage = error.response.data;
              }
              this.$toast.add({
                severity: "error",
                summary: errorMessage,
              });
            }
          )
          .then(() => {
            this.processando(false);
          });
      }
    },

    preencherDadosLogradouro(logradouro) {
      this.processando(true);
      this.cred.cep = logradouro.cepLogradouro;
      //this.solicitacaoCadastro.bairro = logradouro.nomeBairro;
      //this.solicitacaoCadastro.tipoLogra = logradouro.nomeTipoLogra;
      //this.solicitacaoCadastro.logradouro = logradouro.nomeLogradouro;
      LoginService.findAllById(logradouro.idCepLocalidade)
        .then((response) => {
          this.selectMunicipioSede(response.data);
        })
        .then(() => {
          this.processando(false);
          this.showSelectLogra = false;
        });
    },
    requisitarNovaSenha() {
      if (!this.loginNovaSenha) {
        this.$toast.add({
          severity: "warn",
          summary: "Informe um Login válido!",
        });
      } else {
        this.loading = true;
        LoginService.recuperarSenha(this.loginNovaSenha, this.idCliente, this.urlSistema).then(
          (response) => {
            console.log(response);
            this.$toast.add({
              severity: "success",
              summary: "Foi enviado um e-mail para recuperação de Senha da sua conta!",
            });
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.$toast.add({
              severity: "warn",
              summary: error.response.data,
            });
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.login {
  width: 100%;
  text-align: center;
  padding-top: 45px;
}

.login .form-title {
  margin-bottom: 20px;
  color: black;
  font-size: 19px;
  font-weight: 400 !important;
}

.login .form-subtitle {
  color: black;
  font-size: 17px;
  font-weight: 300 !important;
  padding-left: 10px;
}

.btnEntrar {
  width: 30%;
}

.login a {
  color: black !important;
  cursor: pointer;
}

.login .copyright {
  text-align: center;
  margin: 10px auto 30px 0;
  padding: 10px;
  color: black;
  font-size: 13px;
}
</style>

